<template>
  <div v-if="isMobile" class="public-head full-box" key="head-mobile" :style="headStyle">
    <div class="public-head-cont">
      <a class="public-head-logo" @click="goHome">
        <img src="@images/icon/logo-white.png" alt="AD-VC logo" />
      </a>
    </div>
    <img src="@images/icon/more.png" class="more" @click="open" />
    <div class="public-head-tab" v-if="isMenuShow" data-aos="slide-left">
      <ul class="public-head-tab-list">
        <li @click="goHome">主页</li>
        <li @click="doClick('#index-news')">核心介绍</li>
        <li @click="doClick('#index-chain')">主营版块</li>
        <li @click="doClick('#industry-field')">创投模式</li>
        <li @click="goPlatform">资源中台</li>
        <li @click="doClick('#index-map')">全国公司</li>
        <li @click="goAbout">关于我们</li>
      </ul>
    </div>
    <div class="mask" v-if="isMenuShow" @click="() => (isMenuShow = false)"></div>
  </div>
  <div v-else class="public-head full-box" :style="headStyle" key="head-pc">
    <div class="public-head-cont">
      <div class="left">
        <a class="public-head-logo">
          <img src="@images/icon/logo-white.png" alt="AD-VC logo" />
        </a>
        <div class="public-head-tab">
          <ul class="public-head-tab-list">
            <li @click="goHome">主页</li>
            <li @click="doClick('#index-news')">核心介绍</li>
            <li @click="doClick('#index-chain')">主营版块</li>
            <li @click="doClick('#industry-field')">创投模式</li>
            <li @click="goPlatform">资源中台</li>
            <li @click="doClick('#index-map')">全国公司</li>
            <li @click="goAbout">关于我们</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headStyle: {
        position: 'fixed',
        backgroundColor: 'transparent',
      },
      isMenuShow: false,
    };
  },
  inject: ['isMobile'],
  methods: {
    goPlatform() {
      window.open('https://advcback.onein.cn/dateplatform', '_blank');
    },
    doClick(anchor) {
      this.isMenuShow = false;
      if (this.$route.name !== 'Home') {
        this.$router.replace({ path: '/' });
      }
      setTimeout(() => $('html').animate({ scrollTop: $('.' + anchor.slice(1)).offset().top - 88 }, 800), 300);
    },
    goAbout() {
      this.isMenuShow = false;
      if (this.$route.name === 'About') {
        return;
      }
      this.$router.replace({ path: '/about' });
    },
    goHome() {
      this.isMenuShow = false;
      if (this.$route.name !== 'Home') {
        this.$router.replace({ path: '/' });
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      this.$nextTick(() => {
        if (this.isMobile) {
          Object.assign(this.headStyle, {
            backgroundColor: '#767676',
          });
        } else {
          if (window.pageYOffset <= 0) {
            Object.assign(this.headStyle, {
              backgroundColor: this.$route.name === 'Home' ? 'transparent' : 'rgba(0, 0, 0, 0.7)',
            });
          } else {
            Object.assign(this.headStyle, {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            });
          }
        }
      });
    },
    open() {
      this.isMenuShow = true;
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to && to.name) {
          if (this.isMobile) {
            Object.assign(this.headStyle, {
              position: 'fixed',
              backgroundColor: '#767676',
            });
          } else {
            Object.assign(this.headStyle, {
              position: to.name === 'About' ? 'relative' : 'fixed',
              backgroundColor: to.name === 'Home' ? 'transparent' : 'rgba(0, 0, 0, 0.7)',
            });
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
};
</script>

<style scoped lang="scss">
.public-head {
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  color: #ccc;
  transition: 300ms all;
  height: 90px;
  background-color: rgba(0, 0, 0, 0.7);
  a {
    color: #ccc;
  }
  .white {
    color: #fff;
  }
  &-cont {
    width: 90%;
    margin: auto;
    display: flex;
    height: 90px;
    .left {
      display: flex;
      align-items: center;
    }
  }
  &-logo {
    width: 220px;
    img {
      width: 100%;
    }
  }
  &-tab {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    ul {
      display: flex;
      justify-content: center;
      li {
        padding: 0 20px;
        font-size: 18px;
        cursor: pointer;
        line-height: 90px;

        &:hover {
          cursor: pointer;
          background-color: rgba(189, 25, 47, 0.5);
        }
      }
    }
  }
}

.mobile {
  .mask {
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    content: '';
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
  }
  .public-head {
    height: 50px;
    padding-top: 10px;
    &-cont {
      height: 100%;
    }
    &-logo {
      width: 130px;
      img {
        width: 100%;
        height: 30px;
      }
    }
    .more {
      width: 25px;
      position: absolute;
      right: 14px;
      top: 12px;
      line-height: 50px;
      text-align: center;
      display: inline-block;
    }
    &-tab {
      background: white;
      height: 100vh;
      width: 240px;
      right: 0;
      left: initial;
      z-index: 10;
      ul {
        flex-direction: column;
        li {
          line-height: 60px;
          color: black;
        }
      }
    }
  }
}
</style>
