<template>
  <div class="main">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Main',
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    goAnchor(selector) {
      try {
        document.querySelector(selector).scrollIntoView({
          behavior: 'smooth',
        });
      } catch (e) {
        console.log('anchor error');
      }
    },
  },
};
</script>
