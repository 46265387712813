<template>
  <div class="footer">
    <div class="help full-box">
      <div class="content unfull-box">
        <ul class="contact">
          <li v-if="false">
            <img src="@images/icon/newLogo.png" alt="AD-VC logo" class="logo" />
          </li>
          <li>
            <video
              preload="preload"
              src="https://advc-business-1258680678.cos.ap-guangzhou.myqcloud.com/advc/videos/advc_bottom_video.mp4"
              autoplay="autoplay"
              loop="loop"
              width="500"
              height="300"
              controls="controls"
              disableRemotePlayback="true"
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
            ></video>
          </li>
        </ul>
        <div>
          <div class="links">
            <ul class="introduce list">
              <li class="title">企业介绍</li>
              <li @click="doAnchor('#introduction')">
                <span>企业概况</span>
              </li>
              <li @click="doAnchor('#culture')">
                <span>企业文化</span>
              </li>
              <li @click="doAnchor('#history')">
                <span>发展历程</span>
              </li>
            </ul>

            <ul class="about list">
              <li class="title">关于我们</li>
              <li @click="doAnchor('#index-news')"><span>企业动态</span></li>
              <li @click="doAnchor('#founder')"><span>创始人团队</span></li>
              <li>
                <span @click="goRecruit">加入AD-VC (招聘)</span>
              </li>
              <li><span @click="goBP">BP投递</span></li>
            </ul>
          </div>
        </div>
        <ul class="qrcode">
          <li>
            <img src="@images/icon/qrcode1.jpg" alt="" width="100px" />
          </li>
          <li>
            <img src="@images/icon/qrcode2.jpg" alt="" width="100px" />
          </li>
        </ul>
      </div>
    </div>

    <div class="copyright">
      <div>
        © 2021 湖南兔简文化产业发展有限公司版权所有
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color: red; margin-left: 14px"
          >湘ICP备20001437号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
export default {
  methods: {
    initData() {
      anime({
        targets: '.anime-wx',
        translateY: [
          { value: 0, duration: 0, delay: 800 },
          { value: -20, duration: 500, delay: 200 },
          { value: 0, duration: 200, delay: 0 },
          { value: -10, duration: 200, delay: 0 },
          { value: 0, duration: 200, delay: 0 },
          { value: -5, duration: 200, delay: 0 },
          { value: 0, duration: 200, delay: 0 },
          { value: 0, duration: 0, delay: 7200 },
        ],
        loop: true,
        easing: 'easeInOutSine',
      });
      anime({
        targets: '.anime-wb',
        translateY: [
          { value: 0, duration: 0, delay: 1000 },
          { value: -20, duration: 500, delay: 200 },
          { value: 0, duration: 200, delay: 0 },
          { value: -10, duration: 200, delay: 0 },
          { value: 0, duration: 200, delay: 0 },
          { value: -5, duration: 200, delay: 0 },
          { value: 0, duration: 200, delay: 0 },
          { value: 0, duration: 0, delay: 7000 },
        ],
        loop: true,
        easing: 'easeInOutSine',
      });
      anime({
        targets: '.anime-dy',
        translateY: [
          { value: 0, duration: 0, delay: 1200 },
          { value: -20, duration: 500, delay: 200 },
          { value: 0, duration: 200, delay: 0 },
          { value: -10, duration: 200, delay: 0 },
          { value: 0, duration: 200, delay: 0 },
          { value: -5, duration: 200, delay: 0 },
          { value: 0, duration: 200, delay: 0 },
          { value: 0, duration: 0, delay: 6800 },
        ],
        loop: true,
        easing: 'easeInOutSine',
      });
    },
    doAnchor(id) {
      if (id.includes('news')) {
        if (this.$route.name !== 'Home') {
          this.$router.replace({ path: '/' });
        }
        setTimeout(() => $('html').animate({ scrollTop: $('.' + id.slice(1)).offset().top }, 800), 300);
      } else {
        if (this.$route.name !== 'About') {
          this.$router.replace({ path: '/about' });
        }
        setTimeout(() => $('html').animate({ scrollTop: $('.' + id.slice(1)).offset().top - 60 }, 800), 300);
      }
    },
    goRecruit() {
      this.$router.replace({ path: '/recruit' });
    },
    goBP() {
      this.$router.replace({ path: '/bp' });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped lang="scss">
.footer {
  position: relative;
  z-index: 1;
  .help {
    background-color: #20273a;
    color: white;
    overflow: hidden;
    .content {
      margin-top: 100px;
      margin-bottom: 100px;
      letter-spacing: 3px;
      display: flex;
      justify-content: space-between;
      .links {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        color: #585b6c;
        text-align: left;
      }
      .logo {
        width: 200px;
      }
      .contact {
        li {
          &:nth-child(2) {
            margin-top: 10px;
            margin-bottom: 10px;
            .phone {
              margin: 15px 0px;
              font-size: 32px;
              font-weight: 600;
              letter-spacing: 1px;
            }
          }
        }
        .more-contact {
          div {
            margin: 15px 0px;
          }
          img {
            position: relative;
            margin-right: 30px;
          }
        }
      }
      .list {
        margin-right: 80px;
        font-size: 14px;
        .title {
          color: white;
          font-size: 22px;
          padding: 20px 0px;
          border-bottom: 1px solid #595c6b;
        }
        li {
          margin-bottom: 21px;
          span {
            &:hover {
              margin-bottom: 20px;
              border-bottom: 1px solid #595c6b;
              cursor: pointer;
            }
          }
        }
        &:first {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
      .slogan {
        color: #585b6c;
        text-align: right;
        border-bottom: solid 1px #585b6c;
        padding-bottom: 10px;
      }
      .qrcode {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100px;
      }
    }
  }
  .copyright {
    background-color: #303743;
    text-align: center;
    color: #a7a8b4;
    overflow: hidden;
    padding: 20px 0;
    font-size: 15px;
  }
}
.more-contact {
  position: relative;
}

.mobile {
  .footer {
    .help {
      height: 450px;
      .content {
        margin: 0;
        margin-top: 20px;
        width: 100%;
        .unfull-box {
          width: 100%;
        }
        .contact {
          margin: 0 auto;
        }
        video {
          width: 360px;
          height: 200px;
          margin: 0 auto;
        }
        .links {
          position: absolute;
          left: 0;
          right: 0;
          top: 230px;
          justify-content: flex-start;
          padding-left: 20px;
        }
        .list {
          margin-right: 20px;
          .title {
            font-size: 16px;
          }
          li {
            margin-bottom: 15px;
          }
        }
        .qrcode {
          position: absolute;
          top: 246px;
          right: 14px;
          margin: 0 auto;
          width: 80px;
          li {
            img {
              width: 80px;
              height: 80px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .copyright {
      padding: 10px 0;
      font-size: 12px;
      a {
        display: block;
        margin-top: 8px;
      }
    }
  }
}
</style>
